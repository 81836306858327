(function() {
    angular.module('EntrakV5').controller('hubController', hubController);

    function hubController($scope, $rootScope, $q, Service, KEY, Api, $timeout) {
        console.log('hubController');

        var caller = Api.createApiCaller();
        $scope.btnStatus = {};
        $scope.hubs = [];
        $scope.tags = {};

        $scope.filter = {
            enable: 'enable',
            searchText:''
        }

    /* first load */
        $rootScope.loadingPage = 1;
        caller.call(Api.getBuildings()).then(function(res){
            var nameSorter = Service.getSorter();
            var buildings = [];
            var tmp;
            res.sort(nameSorter);
            res.forEach(function(landlord){
                landlord.buildings.sort(nameSorter);
                for (var i=0; i<landlord.buildings.length; i++){
                    tmp = landlord.buildings[i];
                    buildings.push({
                        name: tmp.name,
                        longName: tmp.name + "(" + landlord.name + ")",
                        id: tmp.id
                    });
                }
            });
            $scope.buildingSelect.setDataSource(new kendo.data.DataSource({
                data: buildings
            }));
            $rootScope.loadingPage--;
        }, function(err){
            if (err === KEY.ignore)
                return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
        });
        $scope.loadHubs = function(){
            $rootScope.loadingPage++;
            caller.call(Api.getHubs()).then(function(res){
                res.forEach(item => {
                  if (item.cargoList) {
                    let tmp = JSON.parse(item.cargoList) || {};
                    item.cargoList = Object.entries(tmp).map(e => ({
                      moduleName: e[0],
                      tag: e[1]
                    }));
                  } else {
                    item.cargoList = [];
                  }
                });
                $scope.hubs = res;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalGetDataFail"));
            });
        }
        $scope.loadHubs();
    /* first load */

        $scope.getTags = function(moduleName, callback) {
          if (["HUB", "OWON", "BACNET", "MODBUS", "SMARTLIGHT"].indexOf(moduleName) != -1) {
            if ($scope.tags[moduleName]) {
              callback();
            } else {
              caller.call(Api.getHubTags(moduleName)).then(function(res){
                $scope.tags[moduleName] = res;
                callback();
              }, function(err){
                  if (err === KEY.ignore)
                      return;
                  $rootScope.loadingPage--;
                  alert(Service.translate("error.generalGetDataFail"));
              });
            }
          }
        }

        $scope.enableDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                name: Service.translate("label.enabledDisabled"),
                value: "all"
            }, {
                name: Service.translate("label.enabled"),
                value: 'enable'
            }, {
                name: Service.translate("label.disabled"),
                value: 'disable'
            }],
            dataTextField: "name",
            dataValueField: "value"
        }

        $scope.hubFilter = function(){
            var enable = $scope.filter.enable;
            var searchText = $scope.filter.searchText.trim().toUpperCase();
            if (enable !== 'all'){
                return function(item){
                    //  search (serial, building and remark)
                    let building = item?.buildings?.some(item => item.name.trim().toUpperCase().includes(searchText));
                    return (!searchText
                        || item?.serialId?.toUpperCase()?.indexOf(searchText) != -1
                        || item?.id?.toUpperCase()?.indexOf(searchText) != -1
                        || item?.remark?.trim()?.toUpperCase()?.indexOf(searchText) != -1
                        || building)
                        && (enable === 'all' || enable === 'enable' && item.enabled || enable === 'disable' && !item.enabled);
                }
            }
        }

        $scope.displayBuildings = function(hub){
            return hub.buildings ? hub.buildings.map(function(b){
                return b.name;
            }).join(", ") : '-';
        }

        $scope.editData = {};
        $scope.editHubWinOpt = {
            title: Service.translate("hub.popup.editHub"),
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }

        $scope.buildingSelectOpt = {
            autoWidth: true,
            // autoClose: false, //kendo bug? if false, the text wont clear when you select an item
            clearButton: false,
            filter: "contains",
            dataSource: [],
            dataTextField: "longName",
            dataValueField: "id"
        }

        $scope.createVirtualHub = function(){
            $rootScope.loadingPage++;
            $scope.btnStatus.saving = true;
            caller.call(Api.createVirtualHub()).then(function(res){
                $scope.btnStatus.saving = false;
                $rootScope.infoPopup.show("button.createVirtualHub", "label.createVirtualHubDesc");
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalSaveFail"));
            });
        }

        $scope.editHub = function(hub){
            event
            $scope.editData = {
                id: hub.id,
                serial: hub.serialId,
                remark: hub.remark,
                enabled: hub.enabled,
                buildingIds: hub.buildings ? hub.buildings.map(function(b){
                    return b.id
                }) : []
            }
            setTimeout(function(){
                $scope.editHubWin.open().center();
            });
        }
        $scope.copystopPropagation = function(event){
            event.stopPropagation()
        }
        $scope.confirmEditHub = function(){
            $scope.btnStatus.saving = true;
            var remark = $scope.editData.remark.trim();
            caller.call(Api.updateHub($scope.editData.id, $scope.editData.buildingIds, $scope.editData.enabled, remark)).then(function(res){
                $scope.editHubWin.close();
                Service.replaceArrItem($scope.hubs, res);
                $scope.btnStatus.saving = false;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalUpdateHub"));
            });
        }

        $scope.deleteHub = function(hub){
            $rootScope.deletePopup.show("hub.popup.deleteHub", "hub.popup.deleteHubDesc", hub.serialId, function(){
                caller.call(Api.deleteHub(hub.id)).then(function(hubId){
                    Service.deleteArrItem($scope.hubs, hubId);
                    $rootScope.deletePopup.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    if (err.message) {
                      alert(err.message);
                    } else {
                      alert(Service.translate("error.generalDeleteFail"));
                    }
                });
            });
        }
        $scope.gwFilter = function(){
            var txt = $scope.filter.searchText.trim().toUpperCase();
            console.log("txt==>",txt);
        }
        $scope.editVersionData = {};
        $scope.editHubVersionOpt = {
            width: "550px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.versionDropdownOpt = {
            dataSource: [],
            dataTextField: "tag",
            dataValueField: "tag"
        }

        $scope.editHubVersion = function(m, hubId){
          let isModule = hubId != null;
          $scope.editHubVersionWin.title(Service.translate(isModule ? "hub.updateHubModule" : "hub.updateHub"));
          if (isModule) {
            $scope.editVersionData.id = hubId;
            $scope.editVersionData.moduleName = m.moduleName;
            $scope.editVersionData.currentVersion = m.tag;
          } else {
            $scope.editVersionData.id = m.id;
            $scope.editVersionData.moduleName = "HUB";
            $scope.editVersionData.currentVersion = m.version;
          }
          $scope.getTags($scope.editVersionData.moduleName, function(){
              let tags = $scope.tags[$scope.editVersionData.moduleName];
              $scope.versionDropdown.setDataSource(new kendo.data.DataSource({ data: tags }));
              $scope.editVersionData.newVersion = tags.length ? tags[0].tag : null;
              setTimeout(function(){
                  $scope.editHubVersionWin.open().center();
              });
          });
        }
        $scope.confirmEditHubVersion = function(){
            $scope.btnStatus.saving = true;
            caller.call(Api.updateHubVersion($scope.editVersionData.id, $scope.editVersionData.newVersion, $scope.editVersionData.moduleName)).then(function(res){
                $scope.btnStatus.saving = false;
                $scope.loadHubs();
                $scope.editHubVersionWin.close();
            }, function(err){
                if (err === KEY.ignore)
                        return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalUpdate"));
            });
        }

        $scope.restartHub = function(id, moduleName){
            $scope.btnStatus.saving = true;
            caller.call(Api.restartHub(id, moduleName)).then(function(res){
                $scope.btnStatus.saving = false;
                $scope.editHubWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalUpdate"));
            });
        }

        $scope.scanGateway = function(){
            $scope.btnStatus.saving = true;
            caller.call(Api.scanGateways($scope.editData.id)).then(function(res){
                $scope.btnStatus.saving = false;
                if (res.success){
                    $scope.editHubWin.close();
                    $rootScope.infoPopup.show("button.scanGateway", "label.scanDesc");
                } else {
                    alert(Service.translate("error.generalScanFail"));
                }
            }, function(err){
                if (err === KEY.ignore)
                        return;
                $scope.btnStatus.saving = false;
                alert(Service.translate("error.generalScanFail"));
            });
        }

    /* offline log */
        $scope.selectedHubId = null;
        $scope.offlineData = null;
        $scope.offlineDataRecords = null;
        $scope.showHubLog = function(id){
            if ($scope.selectedHubId === id){
                $scope.selectedHubId = null;
            } else {
                $scope.loadHubLog(id);
            }
        }
        $scope.loadHubLog = function(id) {
          if (!id)
            return;
          $rootScope.loadingPage++;
          $scope.selectedHubId = id;
          var logDurationInDay = 7;
          var dayLength = 24 * 3600;
          var startTime = new Date();
          startTime.setHours(0, 0, 0, 0);
          startTime = Service.getUnixTimestamp(startTime) - logDurationInDay * dayLength;//get 1 more day before start time
          var displayStartTime = startTime + dayLength;
          caller.call(Api.getHubOnlineLog(id, startTime)).then(function(res){//assume sorted
              if ($scope.selectedHubId === id){
                res = Service.processStatusLog(res, null, displayStartTime, logDurationInDay);
                $scope.offlineData = res.barData;
                $scope.offlineDataRecords = res.displayOnlineLog;
              }
              $rootScope.loadingPage--;
          }, function(err){
              if (err === KEY.ignore)
                  return;
              $scope.offlineData = null;
              $rootScope.loadingPage--;
              alert(Service.translate("error.generalGetDataFail"));
          });
        }

        $scope.barTooltipOpt = {
            filter: ".bar",
            position: "top",
            width: "auto",
            callout: true,
            animation: {
                open: {
                    effects: "zoom",
                    duration: 150
                }
            },
            content: function(e) {
                var t = e.target;
                var txt = Service.timeFmt(parseInt(t.data("start"))) + ' - ' + Service.timeFmt(parseInt(t.data("end")));
                if (t.hasClass("online")){
                    txt += " (" + Service.translate("tenant.online") + ")";
                } else if (e.target.hasClass("offline")){
                    txt += " (" + Service.translate("tenant.offline") + ")";
                }
                return txt;
            },
            show: function(e) {
                e.sender.popup.element.css({"margin-top": "-5px"});
            }
        }
    /* offline log */

        $scope.$on('$destroy', function() {
            console.log("hubController destroy");
            caller.cancel();
        });
    }
})();
